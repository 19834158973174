html {
    border: 0px  !important;
}
@font-face {
    font-family: 'Poiret One';
    src: local('Poiret One'), url(./fonts/PoiretOne-Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }
  @font-face {
    font-family: 'Eurostile Extended';
    src: local('Eurostile Extended'), url(./fonts/EurostileExtendedBlack.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }
body {
    font-family: 'Poiret One', cursive !important;
    /*font-family: 'Eurostile Extended', sans-serif !important;*/
    font-weight:bold;
    background-size: 3.2em;
    /* background-image: url("./images/logos/wordmark\ black\ \(20op\).png");
    background-repeat: repeat; */
}
.no-padding{
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ContactForm {
    padding: 1rem;

}

.form-control {
    padding: 1rem;
    cursor: pointer;
}

